<template>
	<section class="domain_content">
		<!-- Disclaimer -->
		<div class="sub-page">
			<h1>Disclaimer</h1>
			<p>
				The content on <a href="/">https://waproapk.com/</a> is provided for informational purposes only. GB WhatsApp is a third-party modified version of WhatsApp, offering extra features and customization options not available in the official app. While we strive to offer accurate and up-to-date information about GB WhatsApp and similar applications, we cannot guarantee the security, reliability, or legality of using these modified versions.
			</p>
			<p>
				We are not responsible for any damages, data loss, or security issues that may result from using these apps. By accessing this website, you acknowledge that you are solely responsible for your actions and understand the potential risks involved with third-party apps like GB WhatsApp.
			</p>
		</div>
	</section>
</template>
  
  <script>
import '@/css/main.scss'
const title = 'Disclaimer'
const description = 'The content of this site is provided for informational purposes only.'
export default {
	metaInfo: {
		title,
		meta: [
			{
				name: 'description',
				content: description,
			},
			{
				name: 'title',
				content: title,
			},
			{
				property: 'og:title',
				content: title,
			},
			{
				property: 'og:description',
				content: description,
			},
		],
            "link": [{
                "rel": "canonical",
                "href": "https://waproapk.com/disclaimer/"
            }]
	},
}
</script>
  